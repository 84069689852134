import React, { useState, useContext } from "react";
import {
  useGridSelector,
  gridFilteredSortedRowEntriesSelector,
} from "@mui/x-data-grid-pro";
import { FilterContext } from "context/FilterContext";
import MatchModalHeader from "./MatchModalHeader";
import MatchModalBody from "./MatchModalBody";

const MatchModalContent = ({
  candidateSelected = {},
  jobInfo = {},
  title = "",
  apiRef = null,
  closeModal = () => {},
}) => {
  const { isInternalUser } =
    useContext(FilterContext);
  // const { loggedInUserData, userGroup } = useContext(UserContext);
  const rows = useGridSelector(apiRef, gridFilteredSortedRowEntriesSelector);
  const reportsData = rows?.filter((el) => el.model.esai_match).map((el) => el.model);

  const [currentIndex, setCurrentIndex] = useState(
    reportsData?.findIndex((el) => el.id === candidateSelected.id)
  );
  const [currentData, setCurrentData] = useState(candidateSelected);

  return (
    <div>
      <MatchModalHeader
        title={title}
        currentData={currentData}
        setCurrentData={setCurrentData}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        reportsData={reportsData}
        closeModal={closeModal}
      />
      <MatchModalBody 
        data={{ ...jobInfo, ...currentData }}
        isInternalUser={isInternalUser}
      />
    </div>
  )
}

export default MatchModalContent;
