import Submenu from "components/SubMenu/Submenu";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import JobDetailsTab from "./JobDetailsTab";
import {
  getJobDetails,
  getPitchedCandidates,
  getTargetCandidates,
} from "services/FetchApiData";
import TargetedCandidatesTab from "./TargetedCandidatesTab";
import PitchedCandidatesTab from "./PitchedCandidatesTab";
import { FilterContext } from "context/FilterContext";
import moment from "moment";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { UserContext } from "context/UserContext";
import ModalBox from "components/Modal/ModalBox";
import ProfileModalContent from "components/CandidateProfileModalContent/ProfileModalContent";
import MatchModalContent from "components/CandidateProfileModalContent/MatchModalContent";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { uuidv4 } from "helpers/ReusableFunctions";

const JobDetailsModalBody = ({
  currentData = {},
  setCurrentData = () => {},
  loading = false,
  setLoading = () => {},
}) => {
  const { t } = useTranslation();
  const apiTargetCandidatesRef = useGridApiRef();
  const apiPitchedCandidatesRef = useGridApiRef();
  const dataFetch = useRef(false);
  const pitchedCandidatesFetch = useRef(false);
  const targetCandidatesFetch = useRef(false);
  const { language } = useContext(UserContext);
  const { filterStore, isInternalUser, isPresentationEnabled } = useContext(FilterContext);

  const TABS = {
    JOB_DETAILS: t("jobs.jobDetailsView"),
    TARGETED_CANDIDATES: t("jobs.targetedCandidates"),
    PITCHED_CANDIDATES: t("jobs.pitchedCandidates"),
  };

  const [basicActive, setBasicActive] = useState(TABS.JOB_DETAILS);
  const [targetedPagination, setTargetedPagination] = useState({ limit: 1000, offset: 0 });
  const [pitchedPagination, setPitchedPagination] = useState({ limit: 1000, offset: 0 });
  const [pitchedCandidates, setPitchedCandidates] = useState([]);
  const [targetCandidates, setTargetCandidates] = useState([]);
  const [isPitchedCandidatesLoading, setIsPitchedCandidatesLoading] =
    useState(false);
  const [isTargetCandidatesLoading, setIsTargetCandidatesLoading] =
    useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showMatchModal, setShowMatchModal] = useState(false);
  const [candidateSelected, setCandidateSelected] = useState(null);

  const layoutProps = {
    language,
    isInternalUser,
    loading,
    basicActive,
    currentData,
    onRowClick: (data) => handleShowProfileModal(data),
    onShowMatchModal: (e, data) => handleShowMatchModal(e, data),
  };

  const apiRef = useMemo(
    () =>
      basicActive === TABS.PITCHED_CANDIDATES
        ? apiPitchedCandidatesRef
        : apiTargetCandidatesRef,
    [
      TABS.PITCHED_CANDIDATES,
      basicActive,
      apiPitchedCandidatesRef,
      apiTargetCandidatesRef,
    ]
  );

  const fetchJobDetails = useCallback(
    async (position_id) => {
      if (!position_id || dataFetch.current) return;
      try {
        setLoading(true);
        dataFetch.current = true;
        const response = await getJobDetails(position_id);
        const jobData = response?.data?.data?.positions?.client_job_detail;
        setCurrentData((prev) => ({
          ...prev,
          ...jobData,
          description: jobData?.description
            ? JSON.parse(jobData?.description)
            : null,
          tech_stack: jobData?.tech_stack
            ? JSON.parse(jobData?.tech_stack)
            : null,
        }));
      } catch (error) {
        // Handle error case
        console.log(error);
      } finally {
        setLoading(false);
        dataFetch.current = false;
      }
    },
    [setCurrentData, setLoading]
  );

  const fetchPitchedCandidates = useCallback(async (position_id, paginationValues = pitchedPagination) => {
    if (!position_id || pitchedCandidatesFetch.current) return;
    try {
      setIsPitchedCandidatesLoading(true);
      pitchedCandidatesFetch.current = true;
      const response = await getPitchedCandidates(
        position_id,
        paginationValues,
        language,
        isPresentationEnabled
      );
      setPitchedCandidates((prev) => [
        ...(paginationValues?.offset === 0 ? [] : prev),
        ...response?.data?.data?.positions?.pitch_list?.data?.map((el) => ({
          ...el,
          id: el?.candidate_id || uuidv4(),
          age: el?.birthday ? moment().diff(el?.birthday, "years") : "",
          estimated_birth_year: el?.estimated_birth_year
            ? moment().year() - el?.estimated_birth_year
            : "",
        })),
      ]);
      setPitchedPagination({
        ...paginationValues,
        count: response?.data?.data?.positions?.pitch_list?.count || 0,
        hasMore: response?.data?.data?.positions?.pitch_list?.has_more,
      });
    } catch (error) {
      // Handle error case
      console.log(error);
    } finally {
      setIsPitchedCandidatesLoading(false);
      pitchedCandidatesFetch.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPresentationEnabled]);

  const fetchTargetCandidates = useCallback(
    async (position_id, paginationValues = targetedPagination) => {
      if (!position_id || targetCandidatesFetch.current) return;
      try {
        setIsTargetCandidatesLoading(true);
        targetCandidatesFetch.current = true;
        const response = await getTargetCandidates(
          position_id,
          paginationValues,
          language,
          isPresentationEnabled
        );
        setTargetCandidates((prev) => [
          ...(paginationValues?.offset === 0 ? [] : prev),
          ...response?.data?.data?.positions?.target_list?.data?.map((el) => ({
            ...el,
            id: el?.candidate_id || uuidv4(),
            age: el?.birthday ? moment().diff(el?.birthday, "years") : "",
            estimated_birth_year: el?.estimated_birth_year
              ? moment().year() - el?.estimated_birth_year
              : "",
          })),
        ]);
        setTargetedPagination({
          ...paginationValues,
          count: response?.data?.data?.positions?.target_list?.count || 0,
          hasMore: response?.data?.data?.positions?.target_list?.has_more,
        });
      } catch (error) {
        // Handle error case
        console.log(error);
      } finally {
        setIsTargetCandidatesLoading(false);
        targetCandidatesFetch.current = false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPresentationEnabled]
  );

  const handleTargetedPaginationModelChange = ({ pageSize, page }) => {
    let isPageSizeChanged = targetedPagination?.page >= page;
    let newPage = isPageSizeChanged ? 0 : page;
    const newPaging = {
      ...targetedPagination,
      page: newPage,
      limit: pageSize,
      offset: isPageSizeChanged ? 0 : pageSize * page,
    };
    setTargetedPagination(newPaging);
    fetchTargetCandidates(currentData?.position_id, newPaging);
    apiTargetCandidatesRef.current.setPage(newPage);
  };

  const handlePitchedPaginationModelChange = ({ pageSize, page }) => {
    let isPageSizeChanged = pitchedPagination?.page >= page;
    let newPage = isPageSizeChanged ? 0 : page;
    const newPaging = {
      ...pitchedPagination,
      page: newPage,
      limit: pageSize,
      offset: isPageSizeChanged ? 0 : pageSize * page,
    };
    setPitchedPagination(newPaging);
    fetchPitchedCandidates(currentData?.position_id, newPaging);
    apiPitchedCandidatesRef.current.setPage(newPage);
  };

  useEffect(() => {
    setTargetCandidates([]);
    setPitchedCandidates([]);
    apiTargetCandidatesRef.current.setPaginationModel({
      pageSize: targetedPagination?.limit,
      page: 0,
    });
    apiPitchedCandidatesRef.current.setPaginationModel({
      pageSize: pitchedPagination?.limit,
      page: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData?.position_id]);

  useEffect(() => {
    fetchJobDetails(currentData?.position_id);
    fetchPitchedCandidates(currentData?.position_id);
    fetchTargetCandidates(currentData?.position_id);
  }, [
    currentData?.position_id,
    fetchJobDetails,
    fetchPitchedCandidates,
    fetchTargetCandidates,
  ]);

  const tabs = [
    {
      title: TABS.JOB_DETAILS,
      content: (
        <JobDetailsTab
          {...layoutProps}
          dateRange={`${moment(filterStore?.startDate).format(
            "D MMM YYYY"
          )} - ${moment(filterStore?.endDate).format("D MMM YYYY")}`}
          totalPitchedCandidates={pitchedPagination?.count}
          totalTargetCandidates={targetedPagination?.count}
          openCandidateTargetTab={() =>
            setBasicActive(TABS.TARGETED_CANDIDATES)
          }
        />
      ),
    },
    {
      title: TABS.TARGETED_CANDIDATES,
      content: (
        <TargetedCandidatesTab
          {...layoutProps}
          apiRef={apiTargetCandidatesRef}
          loading={isTargetCandidatesLoading}
          targetCandidates={targetCandidates}
          pagination={targetedPagination}
          onPaginationModelChange={handleTargetedPaginationModelChange}
        />
      ),
    },
    {
      title: TABS.PITCHED_CANDIDATES,
      content: (
        <PitchedCandidatesTab
          {...layoutProps}
          apiRef={apiPitchedCandidatesRef}
          loading={isPitchedCandidatesLoading}
          pitchedCandidates={pitchedCandidates}
          pagination={pitchedPagination}
          onPaginationModelChange={handlePitchedPaginationModelChange}
        />
      ),
    },
  ];

  const handleShowProfileModal = (data) => {
    setCandidateSelected(data);
    setShowProfileModal(true);
  };

  const handleCloseModal = () => {
    setShowProfileModal((current) => !current);
  };

  const handleShowMatchModal = (e, data) => {
    e.stopPropagation();
    setCandidateSelected(data)
    setShowMatchModal(true);
  }

  const handleCloseMatchModal = () => {
    setShowMatchModal((current) => !current);
  }

  return (
    <>
      <Submenu
        tabs={tabs}
        basicActive={basicActive}
        setBasicActive={setBasicActive}
      />
      <ModalBox
        show={showProfileModal}
        onHide={handleCloseModal}
        content={
          apiRef?.current?.state && (
            <ProfileModalContent
              isCandidatesAccess
              closeModal={handleCloseModal}
              current={candidateSelected}
              title={t("cardViewText.profileView")}
              apiRef={apiRef}
            />
          )
        }
      />
      <ModalBox
        show={showMatchModal}
        onHide={handleCloseMatchModal}
        content={
          apiRef?.current?.state && (
            <MatchModalContent
              isCandidatesAccess
              closeModal={handleCloseMatchModal}
              candidateSelected={candidateSelected}
              jobInfo={currentData}
              title="Candidate Match Details"
              apiRef={apiRef}
            />
          )
        }
      />
    </>
  );
};

export default memo(JobDetailsModalBody);
