import React, { memo, useCallback, useEffect, useState } from "react";

import Submenu from "components/SubMenu/Submenu";
import ProfileModalBodyLayout from "./ProfileModalBodyLayout";
import {
  ActiveApplicationsHistoryDetail,
  HistoryDetailsLayout,
  PitchHistoryDetails,
  WorkHistory,
} from "./SubComponents";

import "./ProfileModalContent.scss";
import Notes from "./SubComponents/Notes";
import {
  fetchClientQuestions,
  fetchPositionQuestions,
  fetchUniversalQuestions,
  getCandidateActiveApplicationsDetail,
  getCandidatePitchHistory,
} from "services/FetchApiData";
import { questionsTypes } from "helpers/Constant";
import QnABox from "./SubComponents/QnABox";
import CandidateInformation from "./SubComponents/CandidateInformation";
import CandidateJobList from "./SubComponents/CandidateJobList";

const ProfileModalBody = ({
  data,
  pageNo,
  isInternalUser = false,
  isCandidatesAccess = false,
  isPresentationEnabled,
  Clients,
  loggedInUserData,
  userGroup,
}) => {
  const initialOpenedTab = isCandidatesAccess
    ? "Candidate Information"
    : "Current Application";
  const [basicActive, setBasicActive] = useState(initialOpenedTab);
  const [scrollToDetailSectionId, setScrollToDetailSectionId] = useState("");
  const [pitchHistory, setPitchHistory] = useState([]);
  const [activeApplicationHistory, setActiveApplicationHistory] = useState([]);
  const [questions, setQuestions] = useState({
    universal: [],
    client: [],
    position: [],
  });
  const [isQnALoading, setIsQnALoading] = useState(false);

  const layoutProps = {
    data,
    basicActive,
    setBasicActive,
    isInternalUser,
    setScrollToDetailSectionId,
    activeApplicationHistory,
    questions,
    isQnALoading,
  };

  const fetchCandidatePitchHistory = useCallback(
    async (candidate_id) => {
      if (!candidate_id) return;
      try {
        const response = await getCandidatePitchHistory(candidate_id);
        setPitchHistory(
          response?.data?.data?.pipelines?.candidate_pitch_history?.data?.filter(
            (item) =>
              userGroup.includes("internal")
                ? !isPresentationEnabled || item?.client_id === Clients?.value
                : item?.client_id === loggedInUserData?.organization?.id
          )
        );
      } catch (error) {
        // Handle error case
        console.log(error);
      } finally {
      }
    },
    [
      userGroup,
      Clients?.value,
      isPresentationEnabled,
      loggedInUserData?.organization?.id,
    ]
  );

  const fetchCandidateActiveApplicationsDetail = async (candidateId) => {
    if (!candidateId) return;
    try {
      const res = await getCandidateActiveApplicationsDetail(candidateId);
      setActiveApplicationHistory(
        res?.data?.data?.pipelines?.candidate_active_applications?.data
      );
    } catch (error) {
      console.log(error);
    } finally {
    }
  };
  const fetchQuestions = useCallback(
    async (
      type,
      details = {
        candidateId: "",
        clientId: "",
        positionId: "",
      }
    ) => {
      const { candidateId, clientId, positionId } = details;
      if (!candidateId) return;
      const apiCall =
        type === questionsTypes.POSITION && positionId
          ? fetchPositionQuestions(candidateId, positionId)
          : type === questionsTypes.CLIENT && clientId
          ? fetchClientQuestions(candidateId, clientId)
          : type === questionsTypes.UNIVERSAL
          ? fetchUniversalQuestions(candidateId)
          : fetchUniversalQuestions(candidateId);

      setIsQnALoading(true);
      try {
        const res = await apiCall;
        setQuestions((prev) => ({
          ...prev,
          ...res?.data?.data?.candidates?.question_answers,
        }));
      } catch (error) {
        console.error(error);
      } finally {
        setIsQnALoading(false);
      }
    },
    []
  );

  const handleQnAToggle = useCallback(
    (positionId) => {
      const currentExpandedPitch = pitchHistory.find(
        (hist) => hist.position_id === positionId
      );

      if (currentExpandedPitch.position_id) {
        fetchQuestions(questionsTypes.POSITION, {
          candidateId: data?.candidate_id,
          positionId: currentExpandedPitch.position_id,
        });
      }

      if (currentExpandedPitch.client_id) {
        fetchQuestions(questionsTypes.CLIENT, {
          candidateId: data?.candidate_id,
          clientId: currentExpandedPitch.client_id,
        });
      }
    },
    [data?.candidate_id, fetchQuestions, pitchHistory]
  );

  const tabs = [
    ...(isCandidatesAccess
      ? [
          {
            title: "Candidate Information",
            content: (
              <ProfileModalBodyLayout
                {...layoutProps}
                pitchHistory={pitchHistory}
                child={
                  <>
                    <CandidateInformation {...layoutProps} />
                    <WorkHistory {...layoutProps} />
                  </>
                }
              />
            ),
          },
        ]
      : [
          {
            title: "Current Application",
            content: (
              <ProfileModalBodyLayout
                {...layoutProps}
                pitchHistory={pitchHistory}
                child={false}
              />
            ),
          },
        ]),
    ...(isInternalUser
      ? [
          {
            title: "Active Applications",
            content: (
              <ProfileModalBodyLayout
                {...layoutProps}
                child={
                  <>
                    {!isInternalUser && <WorkHistory data={data} />}
                    {
                      <ActiveApplicationsHistoryDetail
                        data={data}
                        isInternalUser={isInternalUser}
                        scrollToDetailSectionId={scrollToDetailSectionId}
                        setScrollToDetailSectionId={setScrollToDetailSectionId}
                        activeApplicationHistory={activeApplicationHistory}
                      />
                    }
                  </>
                }
              />
            ),
          },
          {
            title: "Pitch History",
            content: (
              <ProfileModalBodyLayout
                {...layoutProps}
                pitchHistory={pitchHistory}
                child={
                  <PitchHistoryDetails
                    data={data}
                    pageNo={pageNo}
                    isInternalUser={isInternalUser}
                    scrollToDetailSectionId={scrollToDetailSectionId}
                    setScrollToDetailSectionId={setScrollToDetailSectionId}
                    pitchHistory={pitchHistory}
                  />
                }
              />
            ),
          },
          {
            title: "Targeted Jobs",
            content: (
              <CandidateJobList
                data={data}
              />
            ),
          },
          {
            title: "CM Notes",
            content: (
              <ProfileModalBodyLayout
                {...layoutProps}
                child={<Notes data={data} />}
              />
            ),
          },
        ]
      : []),
    {
      title: "Questions",
      content: (
        <ProfileModalBodyLayout
          {...layoutProps}
          child={
            <>
              <QnABox
                questions={{
                  [questionsTypes.UNIVERSAL]:
                    questions[questionsTypes.UNIVERSAL],
                }}
                isLoading={isQnALoading}
              />
              {pitchHistory.map((pitchDetail, index) => (
                <HistoryDetailsLayout
                  key={index}
                  current={{
                    ...pitchDetail,
                  }}
                  handleToggle={handleQnAToggle}
                  loading={isQnALoading}
                  accordionBody={
                    <QnABox
                      isInternalUser={isInternalUser}
                      isInsideAccordion
                      questions={{
                        position: questions.position,
                        client: questions.client,
                      }}
                    />
                  }
                  showAccordion
                  hideCMDetail
                  hideStage
                />
              ))}
            </>
          }
        />
      ),
    },
  ];

  useEffect(() => {
    if (!data?.candidate_id) return;
    fetchCandidatePitchHistory(data?.candidate_id);
    return () => setPitchHistory([]);
  }, [data?.candidate_id, fetchCandidatePitchHistory]);

  useEffect(() => {
    if (!data?.candidate_id) return;
    fetchCandidateActiveApplicationsDetail(data?.candidate_id);
    return () => setActiveApplicationHistory([]);
  }, [data?.candidate_id]);

  useEffect(() => {
    const candidateId = data?.candidate_id;
    const clientId = data?.app_position?.position_id;
    const positionId = data?.app_position?.position_id;

    if (candidateId) {
      fetchQuestions(questionsTypes.UNIVERSAL, { candidateId });
      if (positionId && !isCandidatesAccess) {
        fetchQuestions(questionsTypes.POSITION, { candidateId, positionId });
      }
      if (clientId && !isCandidatesAccess) {
        fetchQuestions(questionsTypes.CLIENT, { candidateId, clientId });
      }
    }
  }, [
    data?.app_position?.position_id,
    data?.candidate_id,
    fetchQuestions,
    isCandidatesAccess,
  ]);

  useEffect(() => {
    if (basicActive) {
      const activeIndex = tabs?.findIndex((el) => el.title === basicActive);
      setBasicActive(tabs[activeIndex].title);
    }
    // eslint-disable-next-line
  }, [basicActive]);

  return (
    <>
      <Submenu
        tabs={tabs}
        basicActive={basicActive}
        setBasicActive={setBasicActive}
      />
    </>
  );
};

export default memo(ProfileModalBody);
