import { ToggleButtonGroup, ToggleButton } from "@mui/material";

const Toggle = ({ handleToggleChange, options, value }) => {
  return (
    <div className="d-flex justify-content-center align-items-center mt-2 mb-1">
      <ToggleButtonGroup
        exclusive
        orientation="horizontal"
        aria-labelledby="demo-row-radio-buttons"
        name="controlled-radio-buttons-group"
        sx={{
          '& .MuiToggleButton-root': {
            padding: '4px 8px',
            fontSize: '0.675rem',
            borderRadius: '10px',
            color: 'black',
            backgroundColor: '#fff',
          }
        }}
        size="small"
        value={value}
        onChange={handleToggleChange}
      >
        {options.map((option) => (
          <ToggleButton key={option} value={option} aria-label={option}>{option}</ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};

export default Toggle;
