import React, { memo } from "react";
import moment from "moment";
import { Row, Col } from "react-bootstrap";
import UserProfile from "./SubComponents/UserProfile";
import { useTranslation } from "react-i18next";
import { numberWithCommas } from "../../helpers/ReusableFunctions";

const MatchModalBody = ({
  data,
  isInternalUser = false
}) => {
  const layoutProps = {
    data,
    isInternalUser,
  }
  const { t } = useTranslation();

  return (
    <>
      <div className="container perform-card match-modal-body">
        <Row>
          <Col lg={3}>
            <UserProfile {...layoutProps} />
            <div className="card mb-4 user-profile">
              <div className="card-body text-center">
                <div className="badge esai d-flex flex-column gap-2 py-3">
                  <span className="score">{data?.esai_score || "Unknown"}</span>
                  <span className="fs-5">Score</span>
                </div>
                <p className="mb-1 mt-3 profile_subsection-title">
                    Date Added:
                </p>
                <p className="mb-3 profile_subsection-body">
                    {data?.first_targeted ? moment(data.first_targeted).format("MMMM D, YYYY") : "-"}
                </p>
                <p className="mb-1 profile_subsection-title">
                    Most Recent Contact:
                </p>
                <p className="mb-2 profile_subsection-body">
                    {data?.last_targeted ? moment(data.last_targeted).format("MMMM D, YYYY") : "-"}
                </p>
              </div>
            </div>
          </Col>
          <Col lg={9}>
          <div className="card mb-4">
            <div className="card-body">
              <Row className="align-items-baseline">
                <Col md="auto">
                  <p className="profile_subsection-dim_title mb-0">
                    {t("jobs.jobNameEn")}:
                  </p>
                </Col>
                <Col md="auto">
                  <p className="mb-0">
                    {data?.position_name}
                  </p>
                </Col>
              </Row>
              <Row className="align-items-baseline">
                <Col md="auto">
                  <p className="profile_subsection-dim_title mb-0">
                    {t("jobs.jobNameJa")}:
                  </p>
                </Col>
                <Col md="auto">
                  <p className="mb-0">
                    {data?.position_name_ja}
                  </p>
                </Col>
              </Row>
              <hr/>
              <Row className="align-items-baseline">
                <Col md="auto">
                  <p className="profile_subsection-dim_title mb-0">
                    {t("jobs.jobCategory")}:
                  </p>
                </Col>
                <Col md="auto">
                  <p className="mb-0">
                    {data?.role}
                  </p>
                </Col>
              </Row>
              <Row className="align-items-baseline">
                {data?.focus ? (
                  <>
                    <Col md="auto">
                      <p className="profile_subsection-dim_title mb-0">
                        {t("jobs.speciality")}:
                      </p>
                    </Col>
                    <Col md="auto">
                      <p className="mb-0">
                        {data?.focus}
                      </p>
                    </Col>
                  </>
                ) : null}
              </Row>
            </div>
          </div>
          <div className="card mb-4 user-profile">
            <div className="card-body">
              <Row className="align-items-baseline mb-0">
                <Col md={6}>
                  <div className="d-flex align-items-baseline">
                    <p className="profile_subsection-dim_title mb-0 me-3" style={{ minWidth: "150px" }}>
                      English (AI Estimated):
                    </p>
                    <p className="mb-0">
                      {data?.estimated_english}
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="d-flex align-items-baseline">
                    <p className="profile_subsection-dim_title mb-0 me-3" style={{ minWidth: "150px" }}>
                      Age (AI Estimated):
                    </p>
                    <p className="mb-0">
                      {data?.estimated_birth_year}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="align-items-baseline">
                <Col md={6}>
                  <div className="d-flex align-items-baseline">
                    <p className="profile_subsection-dim_title mb-0 me-3" style={{ minWidth: "150px" }}>
                      Japanese (AI Estimated):
                    </p>
                    <p className="mb-0">
                      {data?.estimated_japanese}
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="d-flex align-items-baseline">
                    <p className="profile_subsection-dim_title mb-0 me-3" style={{ minWidth: "150px" }}>
                      Salary (AI Estimated):
                    </p>
                    <p className="mb-0">
                      {numberWithCommas(data?.estimated_salary)}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="card mb-4 user-profile">
            <div className="card-body">
              <p 
                className="mb-1 mt-3 profile_subsection-body fw-bold"
                dangerouslySetInnerHTML={{ __html: data?.esai_match }}
              />
            </div>
          </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default memo(MatchModalBody);
