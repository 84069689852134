import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import SelectUI from "components/Select/SelectUI";
import { FilterContext } from "context/FilterContext";
import { PipelineStageChoices, differentReportsPath } from "helpers/Constant";
import { selectOptionConverter } from "helpers/ReusableFunctions";
import StagesSelector from "./StagesSelector";
import { useTranslation } from "react-i18next";

const TopFilters = ({ resetTimeToHireReportsAllData = () => {} }) => {
  const { t } = useTranslation();
  const { filterStore, setFilterStore, dropdownData, isInternalUser } =
    useContext(FilterContext);

  const haveExtraTopFilters = ["/qualityreport"].concat(differentReportsPath);
  const removedStageOptionsForSuccessKpi = [-2, -1, 2, 3, 90, 91, 92, 93, 94].map(
    (val) => PipelineStageChoices[val]
  );
  const location = useLocation();
  const disableExtraTopFilters = location.pathname.includes("/leaderboard");

  const successKpiStageOptions = dropdownData.StageManagersOptions.filter(
    (option) => !removedStageOptionsForSuccessKpi.includes(option.value)
  );

  const selectedFilterValue = (e, entryName) => {
    setFilterStore((prevFilterStore) => ({
      ...prevFilterStore,
      [entryName]: e,
    }));
  };

  return (
    <>
      {haveExtraTopFilters.includes(location.pathname) && (
        <Row className="mt-3">
          {["/successkpireport", "/successkpireport/leaderboard"].includes(
            location.pathname
          ) && (
            <Col
              md={3}
              className="mt-2 mt-md-0 d-flex gap-3 align-items-center"
            >
              <div className="filter_label">{t("filterNav.stage")}</div>
              <SelectUI
                options={selectOptionConverter(successKpiStageOptions)}
                entryName={"StageManagers"}
                placeholder={t("filterNav.choose")}
                selectedValue={selectedFilterValue}
                className="w-100"
                value={filterStore.StageManagers}
                isDisabled={disableExtraTopFilters}
              />
            </Col>
          )}
          {[
            "/timetohirereport",
            "/timetohirereport/leaderboard",
            "/qualityreport",
          ].includes(location.pathname) && (
            <Col md={5}>
              <div className="mt-2 mt-md-0 d-flex align-items-center justify-content-start">
                <span className="filter_label me-2 flex-shrink-0">
                  {t("filterNav.selectTwoStage")}
                </span>
                <StagesSelector
                  resetReport={resetTimeToHireReportsAllData}
                  isDisabled={disableExtraTopFilters}
                />
              </div>
            </Col>
          )}
          {!isInternalUser &&
            !["/qualityreport"].includes(location.pathname) && (
              <Col
                md={3}
                className="mt-2 mt-md-0 d-flex gap-3 align-items-center"
              >
                <span className="filter_label">{t("filterNav.industry")}</span>
                <SelectUI
                  options={selectOptionConverter(dropdownData.IndustryOption)}
                  entryName={"Industry"}
                  placeholder={t("filterNav.choose")}
                  selectedValue={selectedFilterValue}
                  value={filterStore.Industry}
                  className="flex-grow-1"
                  isDisabled={disableExtraTopFilters}
                />
              </Col>
            )}
        </Row>
      )}
    </>
  );
};

export default TopFilters;
