import React, { memo } from "react";
import { HistoryDetailList } from ".";
import { Col, Row } from "react-bootstrap";

const CandidateInformation = ({ data = {} }) => {
  return (
    <div className="card mb-4">
      <div className="card-body">
        <Row>
          <HistoryDetailList
            title="Title"
            value={data?.current_title || "Unknown"}
          />
        </Row>
        <hr />
        <Row>
          <HistoryDetailList
            title="Company"
            value={data?.current_employer || "Unknown"}
          />
        </Row>
        <hr />
        {data?.background && (
          <>
            <Row>
              <HistoryDetailList
                title="Background"
                value={data?.background || "Unknown"}
              />
            </Row>
            <hr />
          </>
        )}
        <Row>
          <Col md="auto">
            <div className="mb-0 profile_subsection-dim_title ">
              Certifications
            </div>
          </Col>
          <Col md="auto">
            {data?.certifications ? (
              <ul className="list-type-none p-0 m-0">
                <li className="profile_subsection-subtitle mb-0">
                  {data?.certifications}
                </li>
              </ul>
            ) : (
              <p className="profile_subsection-subtitle mb-0">Unknown</p>
            )}
          </Col>
        </Row>
        <hr />
        <Row>
          <HistoryDetailList title="Skills" value={data?.skills || "Unknown"} />
        </Row>
      </div>
    </div>
  );
};

export default memo(CandidateInformation);
