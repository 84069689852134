import React, { useContext, useEffect, useState } from "react";
import { isEmpty, isEqual } from "lodash";
import { useLocation } from "react-router-dom";

import { UserContext } from "../../context/UserContext";
import { FilterContext } from "context/FilterContext";
import {
  selectOptionConverter,
  selectOptionConverterSpeciality,
} from "../../helpers/ReusableFunctions";

import "./Sidebar.scss";
import ButtonUI from "../UI/Button/ButtonUI";
import SelectUI from "../Select/SelectUI";
import FilterIcon from "../../assets/svg/filter-icon.svg";
import SearchIcon from "../../assets/svg/search-icon.svg";
import {
  APPLY_FILTER_PAGE,
  differentReportsPath,
  internalReportsPath,
} from "helpers/Constant";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControlLabel } from "@mui/material";

const Sidebar = () => {
  const { t } = useTranslation();
  const { setMinimize, language, userGroup } = useContext(UserContext);
  const {
    setFilterStore,
    filterStore,
    dropdownData,
    initialFilterStore,
    isPresentationEnabled,
    changeApplyFilterFlag,
    lastAppliedFilterstore,
    setLeftHandFilterParams,
    isDisableApplyButton,
    isInternalUser,
  } = useContext(FilterContext);

  const addStageFilter =
    internalReportsPath
      .filter(
        (item) =>
          ![
            "/jobs",
            "/billingsreport",
            "/meetingconversionreport",
            "/meetingconversionreport/leaderboard",
            "/domainhealth",
            "/qualityreport",
          ].includes(item)
      )
      .includes(window.location.pathname) ||
    (window.location.pathname === "/activepipelinereport" &&
      (userGroup.includes("external") || isPresentationEnabled));

  const [isDisabled, setIsDisabled] = useState(false);
  const location = useLocation();

  const disableSidebarFilters = location.pathname.includes("/leaderboard");
  const multiDisabledNames = [
    "Positions",
    "Speciality",
    "ClientManagers",
    "Company",
    "Industry",
    "RemotePolicy",
    "Visibility",
    "RequiredLanguages",
    "CompanyName",
    "EnglishLevel",
    "JapaneseLevel",
    "Background",
    "Title",
    "Met",
    "Generic",
    "Language",
  ];
  const allSelectableFieldNames = [
    "Status",
    "CandidateManagers",
    "BusinessManagers",
    "StageManagers",
    "Accounts",
  ];

  const ClearableSelectionFieldNames = [
    "Positions",
    "Speciality",
    "Company",
    "CandidateManagers",
    "BusinessManagers",
    "ClientManagers",
    "StageManagers",
    "Industry",
    "RemotePolicy",
    "Visibility",
    "Status",
    "RequiredLanguages",
    "CompanyName",
    "EnglishLevel",
    "JapaneseLevel",
    "Background",
    "Title",
    "Met",
    "Generic",
    "Language",
    "Accounts",
  ];

  if (window.location.pathname === "/successkpireport") {
    const index = allSelectableFieldNames.findIndex(
      (val) => val === "StageManagers"
    );
    allSelectableFieldNames.splice(index, 1);
    multiDisabledNames.push("StageManagers");
  }

  const fieldOptions = [
    ...(!["/candidates", "/domainhealth"].includes(location.pathname)
      ? [
          {
            name: "Positions",
            label: t("sidebarText.position"),
            option: dropdownData?.PositionsOption,
          },
          {
            name: "Speciality",
            label: t("sidebarText.specialization"),
            option: dropdownData?.SpecialityOption,
          },
        ]
      : []),
    ...(addStageFilter
      ? [
          {
            name: "StageManagers",
            label: t("filterNav.stage"),
            option: dropdownData?.StageManagersOptions,
          },
        ]
      : []),
    ...(internalReportsPath.includes(location.pathname) ||
    (differentReportsPath.includes(location.pathname) && isInternalUser)
      ? [
          ...(!["/jobs"].includes(location.pathname)
            ? [
                {
                  name: "CandidateManagers",
                  label: "Candidate Manager",
                  option: dropdownData?.CandidateManagerOptions,
                },
                ...(![
                  "/recentlyactivecandidatesreport",
                  "/meetingconversionreport",
                  "/meetingconversionreport/leaderboard",
                  "/domainhealth",
                ].includes(location.pathname)
                  ? [
                      {
                        name: "BusinessManagers",
                        label: "Business Manager",
                        option: dropdownData?.BusinessManagerOptions,
                      },
                    ]
                  : []),
              ]
            : []),
          ...(!["/", "/domainhealth"].includes(location.pathname)
            ? [
                {
                  name: "ClientManagers",
                  label: "Clients",
                  option: dropdownData?.ClientManagerOptions,
                },
              ]
            : []),
        ]
      : []),
    ...(location.pathname === "/jobs"
      ? [
          ...(isInternalUser
            ? [
                {
                  name: "Industry",
                  label: t("filterNav.industry"),
                  option: dropdownData?.IndustryOption,
                },
                {
                  name: "Visibility",
                  label: "Visibility",
                  option: dropdownData?.Visibility,
                },
              ]
            : []),
          {
            name: "RemotePolicy",
            label: t("jobs.remotePolicy"),
            option: dropdownData?.RemotePolicy,
          },
          {
            name: "Status",
            label: "Status",
            option: dropdownData?.Status,
          },
          {
            name: "RequiredLanguages",
            label: t("filterNav.requiredLanguages"),
            option: dropdownData?.RequiredLanguages,
          },
          {
            name: "ActiveSearching",
            label: t("filterNav.activeSearching"),
            type: "boolean",
          },
        ]
      : []),
    ...(location.pathname === "/candidates"
      ? [
          {
            name: "CompanyName",
            label: "Company Name",
            option: dropdownData?.CompanyName,
          },
          {
            name: "EnglishLevel",
            label: "English Level",
            option: dropdownData?.EnglishLevel,
          },
          {
            name: "JapaneseLevel",
            label: "Japanese Level",
            option: dropdownData?.JapaneseLevel,
          },
          {
            name: "Background",
            label: "Background",
            option: dropdownData?.Background,
          },
          {
            name: "Title",
            label: "Title",
            option: dropdownData?.Title,
          },
        ]
      : []),
    ...(location.pathname.includes("/meetingconversionreport")
      ? [
          {
            name: "Met",
            label: "Met",
            option: dropdownData?.Met,
          },
          {
            name: "Generic",
            label: "Generic",
            option: dropdownData?.BooleanOptions,
          },
          {
            name: "Language",
            label: "Language",
            option: dropdownData?.Language,
          },
          {
            name: "AbString",
            label: "A/B String",
            type: "input",
          },
        ]
      : []),
    ...(["/candidates", "/meetingconversionreport"].some((v) =>
      location.pathname.includes(v)
    )
      ? [
          {
            name: "Tags",
            label: "Tags",
            type: "input",
          },
        ]
      : []),
    ...(["/billingsreport",
        "/meetingconversionreport",
        "/successkpireport",
        "/timetohirereport"].some((v) =>
      location.pathname.includes(v)
    )
      ? [
          {
            name: "Background",
            label: "Background",
            option: dropdownData?.Background,
          },
        ]
      : []),
    ...(location.pathname.includes("/domainhealth")
      ? [
          {
            name: "Accounts",
            label: "Accounts",
            option: dropdownData?.Accounts,
          },
        ]
      : []),
      {
        name: "Advanced",
        label: "Advanced Filter",
        type: "boolean",
      },
  ];

  function replaceNullValWithEmptyArray(objEntries) {
    return objEntries.map(([key, val]) => {
      if (val === null) {
        return [key, []];
      } else {
        return [key, val];
      }
    });
  }

  function updateApplyFilterFlagForCurrentPage() {
    const currentPagePath = location.pathname;
    switch (currentPagePath) {
      case "/":
        return changeApplyFilterFlag(APPLY_FILTER_PAGE.dashboard);
      case "/activepipelinereport":
        return changeApplyFilterFlag(APPLY_FILTER_PAGE.active_pipeline);
      case "/performancereport":
        return changeApplyFilterFlag(APPLY_FILTER_PAGE.employee_branding);
      case "/candidatepipelinereport":
        return changeApplyFilterFlag(APPLY_FILTER_PAGE.candidate_pipeline);
      case "/successkpireport":
        return changeApplyFilterFlag(APPLY_FILTER_PAGE.success_kpi);
      case "/recentlyactivecandidatesreport":
        return changeApplyFilterFlag(
          APPLY_FILTER_PAGE.recently_active_candidate
        );
      case "/timetohirereport":
        return changeApplyFilterFlag(APPLY_FILTER_PAGE.time_to_hire);
      case "/jobs":
        return changeApplyFilterFlag(APPLY_FILTER_PAGE.jobs);
      case "/billingsreport":
        return changeApplyFilterFlag(APPLY_FILTER_PAGE.billings_report);
      case "/meetingconversionreport":
        return changeApplyFilterFlag(
          APPLY_FILTER_PAGE.meeting_conversion_report
        );
      case "/domainhealth":
        return changeApplyFilterFlag(APPLY_FILTER_PAGE.domain_health);
      case "/qualityreport":
        return changeApplyFilterFlag(APPLY_FILTER_PAGE.quality_report);
      default:
        return;
    }
  }

  function isClearFilterActionPossible() {
    const keys = fieldOptions.map((item) => item.name);
    const filteredInitFilter = replaceNullValWithEmptyArray(
      Object.entries(initialFilterStore).filter(([key, val]) =>
        keys.includes(key)
      )
    );
    const filteredLastAppliedFilter = replaceNullValWithEmptyArray(
      Object.entries(lastAppliedFilterstore).filter(([key, val]) =>
        keys.includes(key)
      )
    );
    const finalInitFilterObj = Object.fromEntries(filteredInitFilter);
    const finalLastAppliedFilterObj = Object.fromEntries(
      filteredLastAppliedFilter
    );

    //compare
    return !isEqual(finalInitFilterObj, finalLastAppliedFilterObj);
  }

  function getLeftHandFilters() {
    const keys = fieldOptions.map((item) => item.name);

    const filteredLastAppliedFilter = replaceNullValWithEmptyArray(
      Object.entries(lastAppliedFilterstore).filter(([key, val]) =>
        keys.includes(key)
      )
    );
    const finalLastAppliedFilterObj = Object.fromEntries(
      filteredLastAppliedFilter
    );

    return finalLastAppliedFilterObj;
  }

  function isApplyFilterActionPossible() {
    const keys = fieldOptions.map((item) => item.name);
    const filteredInitFilter = replaceNullValWithEmptyArray(
      Object.entries(lastAppliedFilterstore).filter(([key, val]) =>
        keys.includes(key)
      )
    );

    const filteredCurrFilter = replaceNullValWithEmptyArray(
      Object.entries(filterStore).filter(([key, val]) => keys.includes(key))
    );
    const finalInitFilterObj = Object.fromEntries(filteredInitFilter);
    const finalCurrentFilterObj = Object.fromEntries(filteredCurrFilter);

    //compare
    return !isEqual(finalInitFilterObj, finalCurrentFilterObj);
  }

  const selectedFilterValue = (e, entryName) => {
    setFilterStore((prevFilterStore) => (
        {
          ...prevFilterStore,
          [entryName]: e,
        }
      )
    );
  };

  const applyFilter = () => {
    updateApplyFilterFlagForCurrentPage();
  };

  const clearFilter = () => {
    const keys = fieldOptions.map((item) => item.name);
    setFilterStore((prevFilterStore) => {
      return Object.fromEntries(
        Object.entries(prevFilterStore).map(([key, val]) => {
          if (keys.includes(key)) {
            return [key, initialFilterStore[key]];
          }
          return [key, val];
        })
      );
    });
    updateApplyFilterFlagForCurrentPage();
  };

  const renderInputComponent = (entry) => {
    switch (entry?.type) {
      case "boolean":
        return (
          <FormControlLabel
            control={<Checkbox />}
            checked={filterStore?.[entry.name] ?? false}
            onChange={(e) =>
              selectedFilterValue(e.target.checked ?? null, entry?.name)
            }
            label={entry.label}
            labelPlacement="end"
          />
        );

      case "input":
        return (
          <>
            <div className="category-block ">
              <h3>{entry.label}</h3>
            </div>
            <input
              className="form-control"
              name={entry.name}
              type="text"
              autoComplete="off"
              value={filterStore?.[entry.name]}
              onChange={(e) =>
                selectedFilterValue(e.target.value ?? "", entry?.name)
              }
              disabled={disableSidebarFilters}
            />
          </>
        );

      default:
        return (
          <>
            <div className="category-block d-flex justify-content-between">
              <h3>{entry.label}</h3>
            </div>
            <div>
              <SelectUI
                options={
                  entry?.name === "Speciality"
                    ? selectOptionConverterSpeciality(entry.option)
                    : selectOptionConverter(entry.option)
                }
                placeholder={t("filterNav.choose")}
                isMulti={filterStore.Advanced || !multiDisabledNames.includes(entry?.name)}
                isClearable={
                  ClearableSelectionFieldNames.includes(entry?.name)
                    ? true
                    : false
                }
                selectedValue={selectedFilterValue}
                entryName={entry?.name}
                isDisabled={
                  (entry?.name === "Speciality" && isDisabled) ||
                  disableSidebarFilters
                }
                isRenderInputCheckbox={filterStore.Advanced || allSelectableFieldNames.includes(entry?.name)}
                allowSelectAll={filterStore.Advanced || allSelectableFieldNames.includes(entry?.name)}
              />
            </div>
          </>
        );
    }
  };

  useEffect(() => {
    if (isEmpty(filterStore.Positions)) {
      setFilterStore((prev) => ({ ...prev, Speciality: [] }));
      setIsDisabled(true);
    } else if (dropdownData.SpecialityOption.length === 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStore.Positions, dropdownData.SpecialityOption]);

  useEffect(() => {
    setLeftHandFilterParams(() => getLeftHandFilters());
    // eslint-disable-next-line
  }, [lastAppliedFilterstore]);

  return (
    <div className="select-category-block mb-4">
      <div className="card bg-transparent sidebar-height">
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between flex-wrap gap-1">
            <div className="d-flex align-items-baseline">
              {language === "en" ? (
                <h4>{t("dashboardText.filter")}</h4>
              ) : (
                <h3>{t("dashboardText.filter")}</h3>
              )}
              <img className="ms-1" src={FilterIcon} alt="filter icon" />
            </div>
            {
              //d-block d-md-none d-xl-block  - To hide minimize text
              language === "en" ? (
                <h6
                  className="card-title label minimize-label ms-0 ms-md-auto ms-xl-0 mb-0"
                  onClick={() => setMinimize(true)}
                >
                  {t("dashboardText.minimize")}
                </h6>
              ) : (
                <div
                  className="card-title label minimize-label ms-0 ms-md-auto ms-xl-0 mb-0"
                  onClick={() => setMinimize(true)}
                  style={{ fontSize: "13px" }}
                >
                  {t("dashboardText.minimize")}
                </div>
              )
            }
          </div>

          <div>
            <div className="search-bar mt-4">
              <div className="search-input">
                <img src={SearchIcon} alt="search" loading="lazy" />
                <input
                  placeholder="Search"
                  name="search"
                  type="text"
                  id="searchtext"
                  className="form-control"
                  autoComplete="off"
                />
              </div>
            </div>
          </div>

          {fieldOptions?.map((entry, i) => (
            <div className={entry.type !== "boolean" ? "select-category" : ""} key={i}>
              {renderInputComponent(entry)}
            </div>
          ))}
          <div className="d-flex justify-content-center clear-button gap-2">
            <ButtonUI
              disabled={
                !isApplyFilterActionPossible() ||
                isDisableApplyButton ||
                disableSidebarFilters
              }
              title={"Apply"}
              onClick={() => {
                applyFilter();
              }}
            />
            <ButtonUI
              disabled={!isClearFilterActionPossible() || disableSidebarFilters}
              title={"Clear All"}
              onClick={() => {
                clearFilter();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
