import { isEmpty } from "lodash";
import { t } from "i18next";
import moment from "moment";
import linkdinIcon from "../assets/svg/Logos/icons/linkdin.png";
import gitHubIcon from "../assets/svg/Logos/icons/github.png";
import twitterIcon from "../assets/svg/Logos/icons/twitter.png";
import facebookIcon from "../assets/svg/Logos/icons/facebook.png";
import trelloIcon from "../assets/svg/Logos/icons/trello.png";
import outreach from "../assets/svg/Logos/icons/outreach.png";
import zoho from "../assets/svg/Logos/icons/zoho.png";

import {
  PipelineStageChoices,
  PipelineStageText,
  PitchResponseChoices,
  reportToFilterKeysMappingForExternalUser,
  reportToFilterKeysMappingForInternalUser,
  dateOptions,
  defaultStage,
} from "./Constant";

export const selectOptionConverter = (Options) => {
  return Options?.map((x) =>
    x.value && x.label
      ? {
          value: x.value,
          label: x.label,
        }
      : {
          value: x,
          label: x
            .replace(/_/g, " ")
            .toLowerCase()
            .replace(/\b\w/g, (c) => c.toUpperCase()),
        }
  );
};
export const selectOptionConverterSpeciality = (Options) => {
  function transformLabel(label) {
    const transformedLabel = label
      .replace(/_/g, " ")
      .toLowerCase()
      .replace(/\b\w/g, (c) => c.toUpperCase());

    if (transformedLabel.includes("Product")) {
      return transformedLabel.replace("Product", `"Product"`);
    } else {
      return transformedLabel;
    }
  }
  return Options?.map((x) => {
    return {
      value: x,
      label: transformLabel(x),
    };
  });
};
export const convertData = (data) => {
  return Object.entries(data).map(([key, value]) => ({
    name: key,
    value: value ?? 0,
  }));
};
export const capitalizeLetter = (str) => {
  if (typeof str !== "string" || str.length === 0) {
    return str; // Return the input as-is if it's not a non-empty string
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const reformatString = (x) => {
  return x
    ?.replace(/_/g, " ")
    ?.toLowerCase()
    ?.replace(/\b\w/g, (c) => c.toUpperCase());
};

export const isValidEmail = (email) => {
  // eslint-disable-next-line
  const validEmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
  return validEmailRegex.test(email);
};

export const nameConvention = (nameConvention, entry) => {
  return nameConvention.find((item) => item.name === entry.name);
};

export const calculateAge = (birthdate) => {
  const birthDate = new Date(birthdate);
  const currentDate = new Date();
  let age = currentDate.getFullYear() - birthDate.getFullYear();
  return age;
};

export const getBenchmarkData = (dataList = [], benchmark) => {
  const index = dataList.findIndex((item) => item.benchmark === benchmark);
  return index > -1 ? dataList[index].data : {};
};

export const convertGridColumnMenu = (language) => {
  const convertLanguage =
    language === "en"
      ? {}
      : {
          columnMenuManageColumns: t("gridText.manageColumns"),
          columnMenuSortAsc: t("gridText.sortbyAsc"),
          columnMenuSortDesc: t("gridText.sortbyDesc"),
          columnMenuFilter: t("gridText.filter"),
          columnMenuHideColumn: t("gridText.hideColumn"),
          columnMenuShowColumns: t("gridText.sowColumn"),
          pinToLeft: t("gridText.pinToLeft"),
          pinToRight: t("gridText.pinToRight"),
          unpin: t("gridText.unpin"),
          columnsPanelShowAllButton: t("gridText.showAll"),
          columnsPanelHideAllButton: t("gridText.hideAll"),
        };

  return convertLanguage;
};

export const getBase64 = (file) => {
  return new Promise((resolve) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
    } else {
      resolve(null);
    }
  });
};

// export const getReportCardColor = (index) => {
//   const n = reportCardColors?.length;
//   return reportCardColors[index % n];
// };

export const countCurrentStages = (data) => {
  const stageCounts = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    20: 0,
    90: 0,
    91: 0,
    92: 0,
    93: 0,
    94: 0,
  };

  data.forEach((item) => {
    const currentStage = item?.app_position?.current_stage;
    if (currentStage in stageCounts) {
      stageCounts[currentStage]++;
    } else {
      stageCounts[currentStage] = 1;
    }
  });

  return stageCounts;
};

export const getDefaultStages = (language) => {
  const defaultStageManagerOptions = Object.keys(PipelineStageChoices)?.map(
    (key) => {
      return (
        defaultStage.includes(parseInt(key)) && {
          value: PipelineStageChoices[key],
          label: reformatString(
            PipelineStageText(language)[PipelineStageChoices[key]]
          ),
        }
      );
    }
  );

  return defaultStageManagerOptions.filter((el) => el !== false);
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const getThreeMonthsOldDate = (date) => {
  // Copy date so don't affect original
  var d = new Date(date);
  // Get the current month number
  var m = d.getMonth();
  // Subtract 3 months
  d.setMonth(d.getMonth() - 3);
  // If the new month number isn't m - 3, set to last day of previous month
  // Allow for cases where m < 3
  var diff = (m + 3 - d.getMonth()) % 12;

  if (diff < 3) d.setDate(0);

  return d;
};

export const orderArrayOfObjectSimilarToRefArrayOfKeys = (
  arrayObj,
  refKeysArray,
  keyName
) => {
  arrayObj.sort(
    (a, b) =>
      refKeysArray.indexOf(a[keyName]) - refKeysArray.indexOf(b[keyName])
  );
  return arrayObj;
};

export const getColumnsAfterChangeOrder = (item, columns) => {
  switch (item.field) {
    case "first_name":
      return columns.find((col) => col.field === "kanji_last");
    case "last_name":
      return columns.find((col) => col.field === "kanji_first");
    case "kanji_first":
      return columns.find((col) => col.field === "last_name");
    case "kanji_last":
      return columns.find((col) => col.field === "first_name");
    default:
      return columns.find((col) => col.field === item.field);
  }
};

//validate json
export function isJSON(str) {
  try {
    JSON.stringify(JSON.parse(str));
    return true;
  } catch (e) {
    return false;
  }
}

export function convertISODateRangeToLocalDateRange(dateRangeObj) {
  if (isEmpty(dateRangeObj)) return {};
  const tempRange = {};
  const newStartDate = new Date(dateRangeObj.interval_start);
  const newEndDate = new Date(dateRangeObj.interval_end);
  tempRange.interval_start = newStartDate.toLocaleDateString(
    "en-JP",
    dateOptions
  );
  tempRange.interval_end = newEndDate.toLocaleDateString("en-JP", dateOptions);

  return tempRange;
}

export function convertDateToDateRangeSupportedFormat(date) {
  return new Date(
    new Date(date).getFullYear(),
    new Date(date).getMonth(),
    new Date(date).getDate()
  );
}

export function calculateStatsForSuccessKpi(response, currentStage) {
  let success_ratio_datalist = [];
  let total_submission_ratio_datalist = [];

  const convertedData = response.data.data?.reports?.success_kpi;

  if (convertedData?.length === 3) {
    const companyIntervalData = convertedData.filter(
      (item) => item.benchmark === "company"
    )[0]?.intervals;
    const marketIntervalData = convertedData.filter(
      (item) => item.benchmark === "market"
    )[0]?.intervals;
    const industryIntervalData = convertedData.filter(
      (item) => item.benchmark === "industry"
    )[0]?.intervals;

    const companyLastIntervalData =
      companyIntervalData[companyIntervalData.length - 1];

    const marketLastIntervalData =
      marketIntervalData[marketIntervalData.length - 1];

    const industryLastIntervalData =
      industryIntervalData[industryIntervalData.length - 1];

    const companyMetricsData = {
      totalSuccess: companyLastIntervalData.data.percent_success,
      totalSubmission: companyLastIntervalData.data.total,
    };
    const marketMetricsData = {
      totalSuccess: marketLastIntervalData.data.percent_success,
      totalSubmission: marketLastIntervalData.data.total,
    };

    const industryMetricsData = {
      totalSuccess: industryLastIntervalData.data.percent_success,
      totalSubmission: industryLastIntervalData.data.total,
    };

    const range_array = companyIntervalData.map((interval) => interval?.range);

    for (let i = 0; i < range_array.length; i++) {
      const tempRange = convertISODateRangeToLocalDateRange(range_array[i]);

      success_ratio_datalist.push({
        company: companyIntervalData[i].data.percent_success ?? 0,
        market: marketIntervalData[i].data.percent_success ?? 0,
        industry: industryIntervalData[i].data.percent_success ?? 0,
        range: JSON.stringify(tempRange),
        ratioType: "success",
        stage: t(`cardViewText.${currentStage}`) ?? currentStage,
      });

      total_submission_ratio_datalist.push({
        company: companyIntervalData[i]?.data?.total ?? 0,
        companySuccess: companyIntervalData[i]?.data?.success ?? 0,
        companyPending: companyIntervalData[i]?.data?.pending ?? 0,
        companyFailure: companyIntervalData[i]?.data?.failure ?? 0,

        // For External user only
        // market: marketIntervalData[i]?.data?.total ?? 0,
        // marketSuccess: marketIntervalData[i]?.data?.success ?? 0,
        // marketPending: marketIntervalData[i]?.data?.pending ?? 0,
        // marketFailure: marketIntervalData[i]?.data?.failure ?? 0,

        // industry: industryIntervalData[i]?.data?.total ?? 0,
        // industrySuccess: industryIntervalData[i]?.data?.success ?? 0,
        // industryPending: industryIntervalData[i]?.data?.pending ?? 0,
        // industryFailure: industryIntervalData[i]?.data?.failure ?? 0,
        range: JSON.stringify(tempRange),
        ratioType: "submission",
        stage: t(`cardViewText.${currentStage}`) ?? currentStage,
      });
    }

    return {
      metricsData: [companyMetricsData, marketMetricsData, industryMetricsData],
      lineGraphData: [success_ratio_datalist, total_submission_ratio_datalist],
    };
  } else {
    let esaiUserIntervalData = convertedData.filter(
      (item) => item.benchmark === "esai_user"
    )[0].intervals;
    let esaiAllIntervalData = convertedData.filter(
      (item) => item.benchmark === "esai_all"
    )[0].intervals;

    const esaiUserLastIntervalData =
      esaiUserIntervalData[esaiUserIntervalData.length - 1];
    const esaiAllLastIntervalData =
      esaiAllIntervalData[esaiAllIntervalData.length - 1];

    const esaiUserMetricsData = {
      totalSuccess: esaiUserLastIntervalData.data.percent_success,
      totalSubmission: esaiUserLastIntervalData.data.total,
    };
    const esaiAllMetricsData = {
      totalSuccess: esaiAllLastIntervalData.data.percent_success,
      totalSubmission: esaiAllLastIntervalData.data.total,
    };

    const range_array = esaiUserIntervalData.map((interval) => interval?.range);

    for (let i = 0; i < range_array.length; i++) {
      const tempRange = convertISODateRangeToLocalDateRange(range_array[i]);

      success_ratio_datalist.push({
        my: esaiUserIntervalData[i]?.data?.percent_success ?? 0,
        all: esaiAllIntervalData[i]?.data?.percent_success ?? 0,
        range: JSON.stringify(tempRange),
        ratioType: "success",
        stage: t(`cardViewText.${currentStage}`) ?? currentStage,
      });

      total_submission_ratio_datalist.push({
        my: esaiUserIntervalData[i]?.data?.total ?? 0,
        mySuccess: esaiUserIntervalData[i]?.data?.success ?? 0,
        myFailure: esaiUserIntervalData[i]?.data?.failure ?? 0,
        myPending: esaiUserIntervalData[i]?.data?.pending ?? 0,
        all: esaiAllIntervalData[i]?.data.total ?? 0,
        allSuccess: esaiAllIntervalData[i]?.data?.success ?? 0,
        allPending: esaiAllIntervalData[i]?.data?.pending ?? 0,
        allFailure: esaiAllIntervalData[i]?.data?.failure ?? 0,
        range: JSON.stringify(tempRange),
        ratioType: "submission",
        stage: t(`cardViewText.${currentStage}`) ?? currentStage,
      });
    }

    return {
      metricsData: [esaiUserMetricsData, esaiAllMetricsData],
      lineGraphData: [success_ratio_datalist, total_submission_ratio_datalist],
    };
  }
}

export function calculateMetricsForTimeToHire(response) {
  let line_data_list = [];
  let metrics_count_data_list = [];

  const convertedData = response.data.data?.reports?.time_to_hire;

  if (convertedData?.length === 3) {
    const companyIntervalData = convertedData.filter(
      (item) => item.benchmark === "company"
    )[0]?.intervals;
    const marketIntervalData = convertedData.filter(
      (item) => item.benchmark === "market"
    )[0]?.intervals;
    const industryIntervalData = convertedData.filter(
      (item) => item.benchmark === "industry"
    )[0]?.intervals;

    const companyLastIntervalData =
      companyIntervalData[companyIntervalData.length - 1];

    const marketLastIntervalData =
      marketIntervalData[marketIntervalData.length - 1];

    const industryLastIntervalData =
      industryIntervalData[industryIntervalData.length - 1];

    const companyMetricsData = {
      avgDays: companyLastIntervalData.data?.average?.days,
      medianDays: companyLastIntervalData.data?.median?.days,
    };
    const marketMetricsData = {
      avgDays: marketLastIntervalData.data?.average?.days,
      medianDays: marketLastIntervalData.data?.median?.days,
    };
    const industryMetricsData = {
      avgDays: industryLastIntervalData.data?.average?.days,
      medianDays: industryLastIntervalData.data?.median?.days,
    };

    companyIntervalData.forEach((item, index) => {
      let tempRange = convertISODateRangeToLocalDateRange(item?.range);
      line_data_list.push({
        companyAvg: companyIntervalData[index].data?.average?.days ?? 0,
        companyMedian: companyIntervalData[index].data?.median?.days ?? 0,
        marketAvg: marketIntervalData[index].data?.average?.days ?? 0,
        marketMedian: marketIntervalData[index].data?.median?.days ?? 0,
        industryAvg: industryIntervalData[index].data?.average?.days ?? 0,
        industryMedian: industryIntervalData[index].data?.median?.days ?? 0,
        range: JSON.stringify(tempRange),
      });
      metrics_count_data_list = [
        companyMetricsData,
        marketMetricsData,
        industryMetricsData,
      ];
    });
  } else {
    let esaiUserIntervalData = convertedData.filter(
      (item) => item.benchmark === "esai_user"
    )[0]?.intervals;
    let esaiAllIntervalData = convertedData.filter(
      (item) => item.benchmark === "esai_all"
    )[0]?.intervals;
    const esaiUserLastIntervalData =
      esaiUserIntervalData[esaiUserIntervalData.length - 1];
    const esaiAllLastIntervalData =
      esaiAllIntervalData[esaiAllIntervalData.length - 1];

    const esaiUserMetricsData = {
      avgDays: esaiUserLastIntervalData.data?.average.days,
      medianDays: esaiUserLastIntervalData.data?.median.days,
    };
    const esaiAllMetricsData = {
      avgDays: esaiAllLastIntervalData.data?.average.days,
      medianDays: esaiAllLastIntervalData.data?.median.days,
    };

    esaiUserIntervalData.forEach((item, index) => {
      let tempRange = convertISODateRangeToLocalDateRange(item?.range);

      line_data_list.push({
        myAvg: esaiUserIntervalData[index].data?.average?.days ?? 0,
        allAvg: esaiAllIntervalData[index].data?.average?.days ?? 0,
        myMedian: esaiUserIntervalData[index].data?.median?.days ?? 0,
        allMedian: esaiAllIntervalData[index].data?.median?.days ?? 0,
        range: JSON.stringify(tempRange),
      });
      metrics_count_data_list = [esaiUserMetricsData, esaiAllMetricsData];
    });
  }
  return {
    metricsData: metrics_count_data_list,
    lineGraphData: line_data_list,
  };
}

export const getCurrentPageAllFiltersFromFilterStore = (
  filterStore = {},
  isInternalUser = false,
  pageName = ""
) => {
  filterStore = setAdvancedFilter(filterStore);
  let refFilerParamsKeys = {};
  if (isInternalUser) {
    refFilerParamsKeys = reportToFilterKeysMappingForInternalUser[pageName];
  } else {
    refFilerParamsKeys = reportToFilterKeysMappingForExternalUser[pageName];
  }

  if (!isEmpty(filterStore)) {
    return Object.fromEntries(
      Object.entries(filterStore).filter(([key, value]) =>
        refFilerParamsKeys.includes(key)
      )
    );
  }
  return {};
};

export const getSocialLinksImagesWithLinkArrayFromCandidateObj = (
  obj,
  isAddSpecialLinks = true
) => {
  return [
    {
      svg: gitHubIcon,
      url: obj?.github_url,
    },
    {
      svg: linkdinIcon,
      url: obj?.linkedin_url,
    },
    {
      svg: twitterIcon,
      url: obj?.twitter_url,
    },
    {
      svg: facebookIcon,
      url: obj?.facebook_url,
    },
    ...(isAddSpecialLinks
      ? [
          {
            url: obj?.trello_url,
            svg: trelloIcon,
          },
          {
            url: obj?.outreach_url,
            svg: outreach,
          },
          {
            url: obj?.zoho_url,
            svg: zoho,
          },
        ]
      : []),
  ];
};

export function groupingCandidatesByCM(res_data) {
  return res_data?.reduce((final_obj, curr_obj) => {
    let cm_id = curr_obj?.app_position?.candidate_manager.id;
    if (cm_id) {
      if (cm_id in final_obj) {
        final_obj[cm_id] = [...final_obj[cm_id], { ...curr_obj }];
        return final_obj;
      } else {
        return {
          ...final_obj,
          [cm_id]: [{ ...curr_obj }],
        };
      }
    } else {
      return final_obj;
    }
  }, {});
}

export function groupingCandidatesByBM(res_data) {
  return res_data?.reduce((final_obj, curr_obj) => {
    let bm_id = curr_obj?.app_position?.business_manager.id;
    if (bm_id) {
      if (bm_id in final_obj) {
        final_obj[bm_id] = [...final_obj[bm_id], { ...curr_obj }];
        return final_obj;
      } else {
        return {
          ...final_obj,
          [bm_id]: [{ ...curr_obj }],
        };
      }
    } else {
      return final_obj;
    }
  }, {});
}

export function convertStageCodeToName(
  stage,
  pitch_response,
  renderForNull = "",
  language
) {
  return stage
    ? `${t(
        `cardViewText.${PipelineStageChoices[+stage]}`,
        !!language
          ? {
              lng: language,
            }
          : undefined
      )} ${
        +stage === 1
          ? +pitch_response
            ? "( " +
              capitalizeLetter(PitchResponseChoices[pitch_response]) +
              " )"
            : ""
          : ""
      }`
    : renderForNull;
}

export function findStageCodeByKey(givenKey = "") {
  if (givenKey) {
    return Object.entries(PipelineStageChoices).find(
      ([key, value]) => value === givenKey
    )[0];
  }
  return "";
}

export const customStageColumnSorting = (v1, v2, ...rest) => {
  let row1_id = rest[0].id;
  let row2_id = rest[1].id;

  let row1 = rest[0].api.getRow(row1_id);
  let row2 = rest[1].api.getRow(row2_id);

  let intV1 = v1 !== "New Candidate" ? +row1?.[rest[0].field] : 0;
  let intV2 = v2 !== "New Candidate" ? +row2?.[rest[0].field] : 0;
  return intV1 - intV2;
};

export const cleanObject = (obj = {}) => {
  const newObj = { ...obj };

  Object.keys(obj).forEach((key) => {
    if (isEmpty(newObj[key])) {
      delete newObj[key];
    }
  });

  return newObj;
};

export const copyToClipboard = (text = "") => {
  navigator.clipboard.writeText(text);
};

export const delay = (ms) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const uuidv4 = () => {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
    (
      +c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
    ).toString(16)
  );
};

export function calculateStatsForBillingsReport(response) {
  const convertedData = response.data.data?.reports?.billing_totals;

  let esaiUserIntervalData = convertedData.filter(
    (item) => item.benchmark === "esai_user"
  )[0].intervals;
  let esaiAllIntervalData = convertedData.filter(
    (item) => item.benchmark === "esai_all"
  )[0].intervals;

  const esaiUserLastIntervalData =
    esaiUserIntervalData[esaiUserIntervalData.length - 1];
  const esaiAllLastIntervalData =
    esaiAllIntervalData[esaiAllIntervalData.length - 1];

  const totalOffersData = {
    my: esaiUserLastIntervalData.data.total_offers,
    all: esaiAllLastIntervalData.data.total_offers,
  };
  const totalBillingData = {
    my: esaiUserLastIntervalData.data.total_billing,
    all: esaiAllLastIntervalData.data.total_billing,
  };
  const range_array = esaiUserIntervalData.map((interval) => interval?.range);

  let totalOffersDataList = [];
  let totalBillingsDataList = [];
  for (let i = 0; i < range_array.length; i++) {
    const tempRange = convertISODateRangeToLocalDateRange(range_array[i]);

    totalOffersDataList.push({
      my: esaiUserIntervalData[i]?.data?.total_offers ?? 0,
      range: JSON.stringify(tempRange),
      ratioType: "offers",
    });

    totalBillingsDataList.push({
      my: esaiUserIntervalData[i]?.data?.total_billing ?? 0,
      range: JSON.stringify(tempRange),
      ratioType: "billings",
    });
  }

  return {
    metricsData: [totalOffersData, totalBillingData],
    lineGraphData: [totalOffersDataList, totalBillingsDataList],
  };
}

export function calculateStatsForMeetingConversionReport(response) {
  const convertedData = response?.data?.data?.reports?.meeting_conversion;
  const esaiUserIntervalData = convertedData?.[0]?.intervals;
  const esaiUserLastIntervalData =
    esaiUserIntervalData?.[esaiUserIntervalData?.length - 1]?.data;

  const range_array = esaiUserIntervalData.map((interval) => interval?.range);
  let totalEmailsSentAndLeadsDataList = [];
  let totalMeetingsAndPitchesDataList = [];
  let totalPercentagesDataList = [];
  let totalPitchesRatioDataList = [];
  let totalReplyPercentageDataList = [];
  for (let i = 0; i < range_array.length; i++) {
    const tempRange = convertISODateRangeToLocalDateRange(range_array[i]);
    totalEmailsSentAndLeadsDataList.push({
      emailsSent: esaiUserIntervalData[i]?.data?.total_emails ?? 0,
      leads: esaiUserIntervalData[i]?.data?.total_leads ?? 0,
      range: JSON.stringify(tempRange),
      ratioType: "emails",
    });
    totalMeetingsAndPitchesDataList.push({
      positiveReplies: esaiUserIntervalData[i]?.data?.positive_replies ?? 0,
      meetings: esaiUserIntervalData[i]?.data?.num_meetings ?? 0,
      pitches: esaiUserIntervalData[i]?.data?.num_pitches ?? 0,
      targetPitches: esaiUserIntervalData[i]?.data?.num_target_pitches ?? 0,
      range: JSON.stringify(tempRange),
      ratioType: "pitches",
    });
    totalPercentagesDataList.push({
      percentPositive:
        Number(esaiUserIntervalData[i]?.data?.percent_positive) ?? 0,
      percentMeetings:
        Number(esaiUserIntervalData[i]?.data?.percent_meetings) ?? 0,
      range: JSON.stringify(tempRange),
      ratioType: "percentagesPitches",
    });
    totalPitchesRatioDataList.push({
      pitchesRatio: Number(
        esaiUserIntervalData[i]?.data?.pitches_per_meeting ?? 0
      ),
      targetPitchesRatio: Number(
        esaiUserIntervalData[i]?.data?.target_pitches_per_meeting ?? 0
      ),
      range: JSON.stringify(tempRange),
      ratioType: "pitchesRatio",
    });
    totalReplyPercentageDataList.push({
      percentReplied:
        Number(esaiUserIntervalData[i]?.data?.percent_replied) ?? 0,
      range: JSON.stringify(tempRange),
      ratioType: "percentagesReplied",
    });
  }

  return {
    metricsData: [esaiUserLastIntervalData],
    lineGraphData: [
      totalEmailsSentAndLeadsDataList,
      totalMeetingsAndPitchesDataList,
      totalPercentagesDataList,
      totalPitchesRatioDataList,
      totalReplyPercentageDataList,
    ],
  };
}

export const numberWithCommas = (x) => 
  x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const calculateMetricsForQualityReport = (response) => {
  const convertedData = response.data.data?.reports?.quality_score;
  let esaiUserIntervalData = convertedData.filter(
    (item) => item.benchmark === "esai_user"
  )[0]?.intervals;
  let esaiAllIntervalData = convertedData.filter(
    (item) => item.benchmark === "esai_all"
  )[0]?.intervals;

  const esaiUserLastIntervalData =
    esaiUserIntervalData[esaiUserIntervalData.length - 1];
  const esaiAllLastIntervalData =
    esaiAllIntervalData[esaiAllIntervalData.length - 1];

  const esaiUserMetricsData = {
    data: esaiUserLastIntervalData.data || 0,
  };
  const esaiAllMetricsData = {
    data: esaiAllLastIntervalData.data || 0,
  };

  const range_array = esaiUserIntervalData.map((interval) => interval?.range);
  let lineDataList = [];
  for (let i = 0; i < range_array.length; i++) {
    const tempRange = convertISODateRangeToLocalDateRange(range_array[i]);
    lineDataList.push({
      my: Number(esaiUserIntervalData[i]?.data) ?? 0,
      all: Number(esaiAllIntervalData[i]?.data) ?? 0,
      range: JSON.stringify(tempRange),
      ratioType: "quality",
    });
  }

  return {
    metricsData: [esaiUserMetricsData, esaiAllMetricsData],
    lineGraphData: lineDataList,
  };
};

export const formatToLocalISOString = (date) =>
  moment(date).toISOString(true);

export const setAdvancedFilter = (filters) => {
  if (filters.Advanced) {
    let filterList = {};
    Object.entries(filters).forEach(([key, values]) => {
      const newValue = 
        Array.isArray(values) && values.length 
          ? { value: values.map((v) => v.value).join(",") }
          : values;
      filterList = {
        ...filterList,
        [key]: newValue
      }
    });
    return filterList;
  } else {
    return filters;
  }
}
